jQuery(document).ready(function ($) {
  /* if javascript is enabled, hide the submit button, results load asynchronously.
   *  If js is disabled the standard submit btn will show and search will fallback to php
   */
  $("#searchform").find("a[type=submit]").hide();
  // prevent default behaviour when pressing enter key
  $("#searchform").submit(function (e) {
    e.preventDefault();
  });

  // set currentRequest var for aborting any multiple requests after keyup timer
  var currentRequest = null;
  var minlength = 3;

  // keyup triggers change event so user doesnt need to press enter, added delay to stop multiple reqs
  $("#searchform").on("keyup", function () {
    // clear timeout every time something new is typed
    var timer;
    clearTimeout(timer);
    // set a new timer for 500ms
    timer = setTimeout(triggerChange(), 100);
    // trigger the change event below when the timer is finished
    function triggerChange() {
      $("#searchform").change();
    }
  });

  $("#searchform").on("change", function () {
    var $form = $(this);
    var $input = $form.find('input[name="s"]');
    var query = $input.val();
    var $content = $(".search-results");

    if (query.length >= minlength) {
      // aborts multiple requests if any
      if (currentRequest !== null) {
        currentRequest.abort();
        // console.log(currentRequest);
      }

      // console.log($input.val());

      currentRequest = $.ajax({
        type: "post",
        url: myAjax.ajaxurl,
        dataType: "json",
        data: {
          action: "myAjaxFunc",
          query: query,
        },
        success: function (response) {
          var output = "";

          if (response.post !== null) {
            response.post.forEach(function (p) {
              if (query.length >= minlength) {
                if (p.post_type == "brands") {
                  output += "<div class='search-item'>";
                  output += "<a href='" + p.permalink + "'>";
                  output += "<span>" + p.title + "</span>";
                  output +=
                    "<div class='search-thumbnail' style='background-image:url(" +
                    p.thumbnail +
                    ");'></div>";
                  output += "</a>";
                  output += "</div>";
                } else {
                  output += "<div class='search-item'>";
                  output += "<a href='" + p.permalink + "'>";
                  output += "<span>" + p.title + "</span>";
                  output += "</a>";
                  output += "</div>";
                }
              } else {
                output = "";
              }
            }); // Close forEach
          } // Close if post = null
          else {
            output =
              "<div class='search-item'><span>No search results found..</span></div>";
          }

          $content.empty();
          $content.html(output);
        }, // Close success
      }); // Close currentRequest
    } // Close if minlength => 3
    else if (query.length < minlength) {
      if (currentRequest !== null) {
        currentRequest.abort();
        // console.log(currentRequest);
      }
      output = "";
      $content.empty();
      $content.html(output);
    } // Close if minlength < 2

    resetSearch();

    function resetSearch() {
      $("#search-btn.active").click(function () {
        setTimeout(function () {
          currentRequest.abort();
          output = "";
          $input.val("");
          $content.empty();
          $content.html(output);
        }, 300);
      });
    }
  }); // end form onchange function
}); // end document ready