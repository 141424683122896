jQuery(document).ready(function ($) {
  function fixedMenu() {
    //caches a jQuery object containing the header element
    // var header = $(".navbar-header-menu");
    $(window).scroll(function () {
    var scroll = $(window).scrollTop();
      if (scroll >= 300) {
        $(".totop").addClass("visible");
        $(".review-cta-scroll").addClass("animation-scroll-up");
      } else {
        $(".totop").removeClass("visible");
        $(".review-cta-scroll").removeClass("animation-scroll-up");
      }
    });
  }
  // Fire.
  fixedMenu();

});

// jQuery(document).ready(function ($) {
//   function fixedMenu() {
//     //caches a jQuery object containing the header element
//     // var header = $(".navbar-header-menu");
//     $(window).scroll(function () {
//     var scroll = $(window).scrollTop();

//       if ($(window).innerWidth() > 1050) {
//         if (scroll >= 300) {
//           header.addClass("fixed");
//           $("#search-wrap").addClass("fixed");
//           $(".tags-wrap").addClass("fixed");
//           $("main").addClass("padcon");
//           $(".totop").addClass("visible");
//           $(".review-cta-scroll").addClass("animation-scroll-up");
//         } else {
//           header.removeClass("fixed");
//           $("#search-wrap").removeClass("fixed");
//           $(".tags-wrap").removeClass("fixed");
//           $("main").removeClass("padcon");
//           $(".totop").removeClass("visible");
//           $(".review-cta-scroll").removeClass("animation-scroll-up");
//         }
//       } else {
//         $("main").removeClass("padcon");
//         if (scroll >= 300) {
//           $(".totop").addClass("visible");
//           $(".review-cta-scroll").addClass("animation-scroll-up");
//         } else {
//           $(".totop").removeClass("visible");
//           $(".review-cta-scroll").removeClass("animation-scroll-up");
//         }
//       }
//     });
//   }
//   // Fire.
//   fixedMenu();

//   // And recheck when window gets resized.
//   $(window).bind("resize", function () {
//     fixedMenu();
//   });
// });

jQuery(document).ready(function ($) {
  function myAjax() {
    $.ajax({
      type: "POST",
      url: "ajax.php",
      data: { action: "call_this" },
      success: function (html) {
        alert(html);
      },
    });
  }
});

jQuery(document).ready(function ($) {
  onResize = function () {
    if ($(window).innerWidth() >= 1050) {
      $(".bongo-search-header").removeClass("mobile");
      $(".bongo-search-header").removeClass("tablet");
      $(".bongo-search-header").addClass("desktop");
      $(".navigation").removeClass("hide-item");
      $("ul.sub-menu").removeAttr("style");
      $("#menu-navbar-menu .mb-dropdown-icon").removeClass("open");
      $(".site-logo").removeClass("hide-item");

      if ($("#bongo-header-icon").hasClass("active")) {
        $(".bongo-menu-list").removeClass("test");
      }
    } else if ($(window).innerWidth() >= 900) {
      $(".bongo-search-header").removeClass("desktop");
      $(".bongo-search-header").removeClass("mobile");
      $(".bongo-search-header").addClass("tablet");
      $(".site-logo").removeClass("hide-item");
    }

    if ($(window).innerWidth() < 900) {
      $(".bongo-search-header").removeClass("desktop");
      $(".bongo-search-header").removeClass("tablet");
      $(".bongo-search-header").addClass("mobile");
      $(".navigation").removeClass("hide-item");
    }

    if ($(window).innerWidth() < 1050) {
      $(".bongo-menu-list").removeClass("hide-item");

      if (!$("#bongo-header-icon").hasClass("active")) {
        $(".bongo-menu-list").addClass("test");
      }
    }

    if ($(window).innerWidth() < 991) {
      $(".info-container.game-provider .info-header").addClass("collapse-a");
      $(".info-container.game-provider .info-box").css("display", "none");

      $(".filter-form .dropdown-title").addClass("collapse-a");
      $(".filter-form .item-container").css("display", "none");

      $(".filter-form #filter-options-title").addClass("collapse-a");
      // $(".filter-form .all-filter-options").css("display", "none");
    }

    if ($(window).innerWidth() >= 991) {
      $(".info-container.game-provider .info-header").removeClass("collapse-a");
      $(".info-container.game-provider .info-box").css("display", "block");

      // $(".filter-form .dropdown-title").removeClass("collapse-a");
      // $(".filter-form .item-container").css("display", "block");

      // $(".filter-form #filter-options-title").removeClass("collapse-a");
      // // $(".filter-form .all-filter-options").css("display", "block");

      // if (!$(".filter-form #filter-options-title").hasClass("collapse-a")) {
      //   $(".filter-form #filter-options-title").html("Show Filters");
      // }
      $("body").removeClass("overflow");
      $("main").removeClass("overflow-shadow");
      $(".all-filter-options").removeClass("right-to-left");
    }

    if ($(".bongo-search-header").hasClass("desktop")) {
      if ($(".bongo-search-header a").hasClass("active")) {
        $(".bongo-menu-list").addClass("hide-item");
      } else if (!$(".bongo-search-header a").hasClass("active")) {
        $(".bongo-menu-list").removeClass("hide-item");
      }
    }

    if ($(".bongo-search-header").hasClass("tablet")) {
      if (!$(".bongo-search-header a").hasClass("active")) {
        $(".navigation").removeClass("hide-item");
      } else if ($(".bongo-search-header a").hasClass("active")) {
        $(".navigation").addClass("hide-item");
      }
    }

    if ($(".bongo-search-header").hasClass("mobile")) {
      if (!$(".bongo-search-header a").hasClass("active")) {
        $(".site-logo").removeClass("hide-item");
      } else if ($(".bongo-search-header a").hasClass("active")) {
        $(".site-logo").addClass("hide-item");
      }
    }

    if (!$(".bongo-menu-list").hasClass("test")) {
      $(".bongo-menu-list").addClass("visible-inline-desktop");
      $("body").removeClass("overflow");
      $("main").removeClass("overflow-shadow");
      $(".bongo-header-icon").removeClass("active");
      $("ul.sub-menu").removeAttr("style");
      $("#menu-navbar-menu .mb-dropdown-icon").removeClass("open");
    }
  };

  $(document).ready(onResize);

  $(window).bind("resize", onResize);
});

jQuery(document).ready(function ($) {
  $(".totop").on("click touchstart", function () {
    $("html, body").animate(
      {
        scrollTop: $("main").offset().top - 300,
      },
      500
    );
    return false;
  });
});

// function isOnScreen(elem) {
//   // if the element doesn't exist, abort
//   if( elem.length == 0 ) {
//     return;
//   }
//   var $window = jQuery(window)
//   var viewport_top = $window.scrollTop()
//   var viewport_height = $window.height()
//   var viewport_bottom = viewport_top + viewport_height
//   var $elem = jQuery(elem)
//   var top = $elem.offset().top
//   var height = $elem.height()
//   // var form = jQuery('.filter-form')
//   var bottom = top + height

//   return (top >= viewport_top && top < viewport_bottom) ||
//   (bottom > viewport_top && bottom <= viewport_bottom) ||
//   (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
// }

// jQuery( document ).ready( function() {
//   window.addEventListener('scroll', function(e) {
//     if( isOnScreen( jQuery( '.casino-filter-list' ) ) ) { /* Pass element id/class you want to check */
//       setTimeout(function () {
//         jQuery('.filter-form').addClass('active');
//       }, 250);
//       // alert( 'The specified container is in view.' );
//     } else if( !isOnScreen( jQuery( '.casino-filter-list' ) ) ) {
//       setTimeout(function () {
//         jQuery('.filter-form').removeClass('active');
//       }, 250);
//     }
//   });
// });

jQuery(document).ready(function ($) {
  $(".bongo-search-header a").on("click touchstart", function () {
    $(this).toggleClass("active");
    $(".searchfield").toggleClass("open");

    // if ($(".bongo-search-header").hasClass("desktop")) {
    //   if ($(".bongo-search-header a").hasClass("active")) {
    //     $(".bongo-menu-list").addClass("hide-item");
    //   } else if (!$(".bongo-search-header a").hasClass("active")) {
    //     $(".bongo-menu-list").removeClass("hide-item");
    //   }
    // }

    if ($(".bongo-search-header").hasClass("desktop")) {
      if ($(".bongo-search-header a").hasClass("active")) {
        $(".navigation").addClass("hide-item");
      } else if (!$(".bongo-search-header a").hasClass("active")) {
        $(".navigation").removeClass("hide-item");
      }
    }

    if ($(".bongo-search-header").hasClass("tablet")) {
      if ($(".bongo-search-header a").hasClass("active")) {
        $(".navigation").addClass("hide-item");
      } else if (!$(".bongo-search-header a").hasClass("active")) {
        $(".navigation").removeClass("hide-item");
      }
    }

    if ($(".bongo-search-header").hasClass("mobile")) {
      if (!$(".bongo-search-header a").hasClass("active")) {
        $(".site-logo").removeClass("hide-item");
      } else if ($(".bongo-search-header a").hasClass("active")) {
        $(".site-logo").addClass("hide-item");

        $("#bongo-header-icon").removeClass("active");
        $(".bongo-menu-list").addClass("visible-inline-desktop");
        $("body").removeClass("overflow");
        $("main").removeClass("overflow-shadow");
        $("ul.sub-menu").removeAttr("style");
        $(".mb-dropdown-icon").removeClass("open");
      }
    }

    return false;
  });
});

jQuery(document).ready(function ($) {
  // When clicking on id read-more activate function
  $("#read-more").on("click touchstart", function (e) {
    // This makes the site not reload page or add link value to the url when clicking on a link.
    e.preventDefault();

    // If the #read-more has the text "läs mer" inside it change value to "läs mindre" instead.
    $(this).html($(this).html() == "Show less" ? "Show more" : "Show less");

    // Everything inside the class "p-hidden" will animate in speed "fast". Make sure .p-hidden has display: none; in the CSS.
    $(".p-hidden").slideToggle("fast");
    return false;
  });
});

jQuery(document).ready(function ($) {
  // When clicking on id read-more activate function
  $(".toggle-content-list").on("click touchstart", function (e) {
    // This makes the site not reload page or add link value to the url when clicking on a link.
    e.preventDefault();
    $(this).toggleClass("collapse");
    // Everything inside the class "p-hidden" will animate in speed "fast". Make sure .p-hidden has display: none; in the CSS.
    $(".content-list").slideToggle("fast");
    return false;
  });
});

// jQuery(document).ready(function ($) {
//   $(".homepage .featured-block-main .view-more-toggle").on(
//     "click touchstart",
//     function () {
//       $(this).toggleClass("toggle-open");
//       $(".sub-featured").slideToggle("fast");
//       return false;
//     }
//   );

//   onResize = function () {
//     if ($(window).innerWidth() > 991) {
//       $(".sub-featured").removeAttr("style");
//       $(".homepage .featured-block-main .view-more-toggle").removeClass(
//         "toggle-open"
//       );
//     }
//   };

//   $(document).ready(onResize);

//   $(window).bind("resize", onResize);
// });


// jQuery(document).ready(function ($) {
//   function ToggleIframe() {
//     $(".iframe-btn").on("click touchstart", function (e) {
//       e.preventDefault();
//       $(this).toggleClass("collapse-a");
//       var par = $(this).parents(".filter-tag-container");
//       par.find(".item-container").slideToggle("fast");
//     });
//   }
//   ToggleFilterBox();
// });



  // function getLobbyiframeSRC() {
  //   $("#vh-lobby-posts li .vh-thumb-link").on("click touchstart", function (e) {
  //     e.preventDefault();
  //     var iframe = $("#game-iframe");
  //     var data_src = $(this).attr("data-src");
  //     iframe.attr('src', data_src);
  //     $(".iframe-fixed-container").addClass("flex");
  //     }
  //   );
  // }
  // getLobbyiframeSRC();
jQuery(document).ready(function ($) {

  $(document).on("click touchstart", '#vh-lobby-posts li .vh-thumb-link', function(event) { 
      var iframe = $("#game-iframe");
      var data_src = $(this).attr("data-src");
      iframe.attr('src', data_src);
      $(".iframe-fixed-container").addClass("flex");
      $("body").addClass("overflow");
      var par = $(this).parents("#vh-lobby-posts li");
      var game_title = par.find(".vh-game-title").text();
      $(".iframe-fixed-container .cta span").html("Play " + game_title + " → real money");
  });

});

jQuery(document).ready(function ($) {

  function getiframeSRC() {
    $(".cta-box .free-play").on("click touchstart", function (e) {
      e.preventDefault();
      // var par = $(this).parents(".outer-play-container");
      // var overlay = par.find(".image-overlay");
      var iframe = $("#game-iframe");
      var data_src = $(this).attr("data-src");
      iframe.attr('src', data_src);
      $(".iframe-fixed-container").addClass("flex");
      $("body").addClass("overflow");
      }
    );
  }
  getiframeSRC();

  

  function removeiframeSRC() {
    $(".iframe-fixed-container .overlay-iframe, .iframe-fixed-container .icon-cancel").on("click touchstart", function (e) {
        e.preventDefault();
        // var par = $(this).parents(".outer-play-container");
        // var overlay = par.find(".image-overlay");
        var iframe = $("#game-iframe");
        var data_src = "";
        iframe.attr('src', data_src);
        $(".iframe-fixed-container").removeClass("flex");
        $("body").removeClass("overflow");
      }
    );
  }
  removeiframeSRC();

});

jQuery(document).ready(function ($) {
  function ToggleFAQ() {
    $(".schema-faq-section .schema-faq-question").on(
      "click touchstart",
      function (e) {
        e.preventDefault();
        $(this).toggleClass("collapse-a");
        var par = $(this).parents(".schema-faq-section");
        par.find(".schema-faq-answer").slideToggle("fast");
      }
    );
  }
  ToggleFAQ();
});

jQuery(document).ready(function ($) {
  function ToggleInfoBox() {
    $(".review-info .info-container .info-header").on("click touchstart", function (e) {
        e.preventDefault();
        $(this).toggleClass("collapse-a");
        var par = $(this).parents(".info-container");
        par.find(".info-box").slideToggle("fast");
      }
    );
  }
  ToggleInfoBox();
});

jQuery(document).ready(function ($) {
  function ToggleFilterBox() {
    $(".filter-tag-container .dropdown-title").on("click touchstart", function (e) {
      e.preventDefault();
      $(this).toggleClass("collapse-a");
      var par = $(this).parents(".filter-tag-container");
      par.find(".item-container").slideToggle("fast");
    });
  }
  ToggleFilterBox();
});

jQuery(document).ready(function ($) {
  function ToggleAllFilters() {
    $(".filter-form #filter-options-title").on("click touchstart", function (e) {
      e.preventDefault();
      var par = $(this).parents(".filter-form");
      // par.find(".all-filter-options").slideToggle("fast");
      // par.find(".all-filter-options").animate({width:'toggle'},350);
      par.find(".all-filter-options").removeClass("right-to-left");
      par.find(".all-filter-options").addClass("left-to-right");
      $("body").addClass("overflow");
      $("main").addClass("overflow-shadow");
    });
    $(".filter-form .all-filter-options #close-filter").on("click touchstart", function (e) {
      e.preventDefault();
      var par = $(this).parents(".filter-form");
      par.find(".all-filter-options").removeClass("left-to-right");
      par.find(".all-filter-options").addClass("right-to-left");
      $("body").removeClass("overflow");
      $("main").removeClass("overflow-shadow");
      setTimeout(function () {
        par.find(".all-filter-options").removeClass("right-to-left");
      }, 250);
      // par.find(".all-filter-options").animate({width:'toggle'},350);
      // par.find(".all-filter-options").toggle(effect, options, duration);
      // par.find(".all-filter-options").slideToggle("fast");
    });
  }
  ToggleAllFilters();

  // function SubmitFilterRemoval() {
  //   $("#add-filter").on("click touchstart", function (e) {
  //     e.preventDefault();
  //     if ($(".all-filter-options").hasClass("left-to-right")) {
  //       $(".all-filter-options").removeClass("left-to-right");
  //       $("body").removeClass("overflow");
  //       $("main").removeClass("overflow-shadow");
  //     }
  //   });
  // }
  // SubmitFilterRemoval();

});

jQuery(document).ready(function ($) {
  /* Open the hide menu */
  function ToggleMenu() {
    $("#bongo-header-icon").on("click touchstart", function () {
      $(this).toggleClass("active");
      $(".bongo-menu-list").toggleClass("visible-inline-desktop");
      $("ul.sub-menu").removeAttr("style");
      $(".mb-dropdown-icon").removeClass("open");
      if (!($(".bongo-search-header").hasClass("desktop"))) {
        $("body").toggleClass("overflow");
        $("main").toggleClass("overflow-shadow");
      }
      // if ($(".bongo-search-header").hasClass("desktop")) {
      //   $(".navbar-header-menu").slideToggle("fast");
      // }
      $(".navbar-header-menu").slideToggle("fast");
      return false;
    });
  }
  ToggleMenu();
  function ToggleMobileMenuDropdown() {
    var hasChildren = $(".bongo-menu-list ul li.menu-item-has-children");

    hasChildren.each(function () {
      var $btnToggle = $(
        '<a class="mb-dropdown-icon hidden-desktop" href="#"></a>'
      );
      $(this).append($btnToggle);
      $btnToggle.on("click touchstart", function (e) {
        e.preventDefault();
        $(this).toggleClass("open");
        $(this).parent().children("ul").slideToggle("fast");
      });
    });
  }
  ToggleMobileMenuDropdown();
});

jQuery(document).ready(function ($) {
  function parent_drowdown() {
    var hasChildren = $(".related-pages-list.reverse .got-child");

    hasChildren.each(function () {
      var $btnToggle = $('<a class="mb-dropdown-icon open" href="#"></a>');
      $(this).append($btnToggle);
      $btnToggle.on("click touchstart", function (e) {
        e.preventDefault();
        $(this).toggleClass("open");
        $(this).parent().children(".child-list").slideToggle("fast");
      });
    });
  }
  parent_drowdown();
});
